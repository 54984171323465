import store from "@/store";

export const modules = [
  "geovision",
  "analyse",
  "reglementation",
  "monitoring",
  "incident",
  "energie",
  "eau",
  "non-conformite",
  "audit",
  "dechet",
  "moyens",
  "parametrage",
  "utilisateur",
  "permission",
];

export const getUserPermissions = () => {
  const user = store.state.AuthModule.user;
  const userPermissions = {};
  user?.role?.permissions?.forEach(
    (permission: { action: string; module: string }) => {
      if (userPermissions[permission.module]) {
        userPermissions[permission.module].push(permission.action);
      } else {
        userPermissions[permission.module] = [permission.action];
      }
    }
  );
  return userPermissions;
};

export const getUserRole = () => {
  const user = store.state.AuthModule.user;
  return user?.role?.name;
};

const DocMenuConfig = () => {
  const userPermissions = getUserPermissions();
  return [
    {
      pages: [
        {
          heading: "dashboard",
          route: "/dashboard",
          svgIcon: "media/icons/duotune/art/art002.svg",
          fontIcon: "bi-app-indicator",
          show: true,
        },

        {
          heading: "GéoVision",
          route: "/geovision",
          svgIcon: "media/icons/duotune/technology/teh008.svg",
          fontIcon: "bi-app-indicator",
          show: userPermissions["geovision"]?.includes("read"),
        },
      ],
    },
    {
      heading: "Modules",
      route: "/apps",
      pages: [
        {
          sectionTitle: "Analyse environnementale",
          svgIcon: "/media/icons/duotune/medicine/med005.svg",
          fontIcon: "bi-cart",
          show: userPermissions["analyse"]?.includes("read"),
          sub: [
            {
              heading: "Consulter les AE",
              route: "/apps/analyse/analyse-env-list",
              show: userPermissions["analyse"]?.includes("read"),

            },
            {
              heading: "Ajouter un AE",
              route: "/apps/analyse/add-analyse",
                show: userPermissions["analyse"]?.includes("write"),
            },
            {
              heading: "Plan d'action AE",
              route: "/apps/analyse/list-action",
              show: userPermissions["analyse"]?.includes("read"),
            },
            {
              heading: "Indicateur AE",
              route: "/apps/analyse/indicateur-chart",
              show: userPermissions["analyse"]?.includes("read"),
            },
          ],
        },
        {
          sectionTitle: "Réglementation Environnementale\t",
          svgIcon: "/media/icons/duotune/medicine/med006.svg",
          fontIcon: "bi-cart",
          show: userPermissions["reglementation"]?.includes("read"),
          sub: [
            {
              heading: "Consulter les exigences",
              route: "/apps/reglementation/reglementation-list",
              show: userPermissions["reglementation"]?.includes("read"),
            },
            {
              heading: "Créer une éxigence",
              route: "/apps/reglementation/reglementation-add",
              show: userPermissions["reglementation"]?.includes("write"),
            },
            {
              heading: "Autorisations",
              route: "/apps/reglementation/autorisations",
              show: userPermissions["reglementation"]?.includes("read"),
            },
            {
              heading: "Plan d'action éxigence",
              route: "/apps/reglementation/list-action",
              show: userPermissions["reglementation"]?.includes("read"),
            },
            {
              heading: "Indicateur éxigence",
              route: "/apps/reglementation/indicateur-chart",
              show: userPermissions["reglementation"]?.includes("read"),
            },
          ],
        },
        {
          sectionTitle: "Monitoring Environnemental",
          svgIcon: "/media/icons/duotune/medicine/med005.svg",
          fontIcon: "bi-cart",
          show: userPermissions["monitoring"]?.includes("read"),
          sub: [
            {
              heading: "Plan de surveillance",
              route: "/apps/monitoring/monitoring-list",
              show: userPermissions["monitoring"]?.includes("read"),

            },
            {
              heading: "Norme des Analyses",
              route: "/apps/monitoring/normes",
              show: userPermissions["monitoring"]?.includes("write"),

            },
            {
              heading: "Analyse & Résultats",
              route: "/apps/monitoring/analyses",
              show: userPermissions["monitoring"]?.includes("write"),

            },
            {
              heading: "Plan d'action monitoring",
              route: "/apps/point/list-action",
              show: userPermissions["monitoring"]?.includes("read"),

            },
            {
              heading: "Indicateur",
              route: "/apps/monitoring/indicateur-chart",
              show: userPermissions["monitoring"]?.includes("read"),

            },
          ],
        },
        {
          sectionTitle: "Incidents Environnementaux",
          svgIcon: "/media/icons/duotune/medicine/med001.svg",
          fontIcon: "bi-exclamation-circle",
          show: userPermissions["incident"]?.includes("read"),
          sub: [
            {
              heading: "Analyse des incidents",
              route: "/apps/incidents/analyse-list",
              show: userPermissions["incident"]?.includes("read"),

            },
            {
              heading: "Flash incidents",
              route: "/apps/incidents/flash-list",
              show: userPermissions["incident"]?.includes("write"),

            },
            {
              heading: "Registre incidents",
              route: "/apps/incidents/registre-list",
              show: userPermissions["incident"]?.includes("read"),

            },
            {
              heading: "Plan d'action incidents",
              route: "/apps/incidents/list-action",
              show: userPermissions["incident"]?.includes("read"),

            },
            {
              heading: "Indicateur incidents",
              route: "/apps/incidents/indicateur-chart",
              show: userPermissions["incident"]?.includes("read"),

            },
          ],
        },
        {
          sectionTitle: "Énergie",
          svgIcon: "/media/icons/duotune/sections/energy.svg",
          fontIcon: "bi-battery-charging",
          show: userPermissions["energie"]?.includes("read"),
          sub: [
            {
              heading: "Consommation Combustible",
              route: "/apps/consumption/fuel-list",
              show: userPermissions["energie"]?.includes("read"),
            },
            {
              heading: "Indicateurs des Combustibles",
              route: "/apps/consumption/fuel-indicateur",
              show: userPermissions["energie"]?.includes("read"),

            },
            // {
            //   heading: "Plans d'action Combustible",
            //   route: "/apps/consumption/fuel-action",
            // },
            {
              heading: "Consommation Électricité",
              route: "/apps/consumption/electricity-list",
              show: userPermissions["energie"]?.includes("read"),

            },
            // {
            //   heading: "Plans d'action Électricité",
            //   route: "/apps/consumption/electricity-action",
            // },
            {
              heading: "Indicateurs Électricité",
              route: "/apps/consumption/electricity-indicateur",
              show: userPermissions["energie"]?.includes("read"),

            },
          ],
        },
        {
          sectionTitle: "Eau",
          svgIcon: "/media/icons/duotune/sections/water.svg",
          fontIcon: "bi-droplet-half",
          show: userPermissions["eau"]?.includes("read"),
          sub: [
            {
              heading: "Flux & Consommation Hydraulique",
              route: "/apps/consumption/water-list",
              show: userPermissions["eau"]?.includes("read"),

            },
            // {
            //   heading: "Plans d'action Hydrauliques",
            //   route: "/apps/consumption/water-action",
            // },
            {
              heading: "Indicateurs Hydrauliques",
              route: "/apps/consumption/water-indicateur",
              show: userPermissions["eau"]?.includes("read"),

            },
          ],
        },
        {
          sectionTitle: "Non conformité",
          svgIcon: "/media/icons/duotune/sections/gavel.svg",
          fontIcon: "bi-cart",
          show: userPermissions["non-conformite"]?.includes("read"),
          sub: [
            {
              heading: "Consulter les NC",
              route: "/apps/non-conformite/list",
              show: userPermissions["non-conformite"]?.includes("read"),
            },
            {
              heading: "Créer une NC",
              route: "/apps/non-conformite/add",
              show: userPermissions["non-conformite"]?.includes("write"),
            },
            {
              heading: "Plan d'action NC",
              route: "/apps/non-conformite/list-action",
              show: userPermissions["non-conformite"]?.includes("read"),
            },
            {
              heading: "Indicateur NC",
              route: "/apps/non-conformite/indicateur",
              show: userPermissions["non-conformite"]?.includes("read"),

            },
          ],
        },
        {
          sectionTitle: "Audits",
          svgIcon: "/media/icons/duotune/sections/audit.svg",
          fontIcon: "bi-cart",
          show: userPermissions["audit"]?.includes("read"),
          sub: [
            {
              heading: "Calendrier des audits",
              route: "/apps/audit/calendar",
              show: userPermissions["audit"]?.includes("read"),

            },
            {
              heading: "Audits",
              route: "/apps/audit/my-list",
              show: userPermissions["audit"]?.includes("read"),
            },
            {
              heading: "Rapports",
              route: "/apps/audit/list-rapport",
              show: userPermissions["audit"]?.includes("read"),
            },
            {
              heading: "Auditeurs",
              route: "/apps/audit/list-auditeur",
              show: userPermissions["audit"]?.includes("read"),
            },
            {
              heading: "Évaluation des auditeurs",
              route: "/apps/audit/notation",
              show: userPermissions["audit"]?.includes("read"),
            },
            {
              heading: "Plan d'action Audit",
              route: "/apps/audit/list-action",
              show: userPermissions["audit"]?.includes("read"),
            },
          ],
        },
        {
          sectionTitle: "Déchets",
          svgIcon: "/media/icons/duotune/sections/dechet.svg",
          fontIcon: "bi-cart",
          show: userPermissions["dechet"]?.includes("read"),
          sub: [
            {
              heading: "Bilan Déchet",
              route: "/apps/dechet/list",
            },
            {
              heading: "Gestion mensuelle de déchet",
              route: "/apps/dechet/month",
            },
            {
              heading: "Indicateur",
              route: "/apps/dechet/indicateur",
            },
            {
              heading: "Bordereau",
              route: "/apps/dechet/bordereau",
            },
          ],
        },
        {
          sectionTitle: "Moyens",
          svgIcon: "/media/icons/duotune/sections/compass.svg",
          fontIcon: "bi-cart",
          show: userPermissions["moyens"]?.includes("read"),
          sub: [
            {
              heading: "Suivi des équipements",
              route: "/apps/suivi/list",
            },
            {
              heading: "Liste des sous traitants",
              route: "/apps/suivi/sous-traitant",
            },
            {
              heading: "Monitoring des équipements",
              route: "/apps/suivi/monitoring",
            },
          ],
        },
      ],
    },
  ];
};

export default DocMenuConfig;
