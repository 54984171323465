import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import i18n from "@/core/plugins/i18n";
import "viewerjs/dist/viewer.css";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import "@/core/plugins/prismjs";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as directives from "vuetify/directives";
import * as components from "vuetify/components";
import "@mdi/font/css/materialdesignicons.css";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import VueViewer from "v-viewer";
import PermissionWrapper from "@/components/PermissionWrapper.vue";
// Translations provided by Vuetify
import {  fr } from 'vuetify/locale'

const myCustomLightTheme = {
    dark: false,
    colors: {
        background: "#235153",
        surface: "#235153",
        primary: "#e2b842",
        "primary-darken-1": "#c0b510",
        secondary: "#03DAC6",
        "secondary-darken-1": "#018786",
        error: "#ef3356",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
    },
    light: {
        primary: "#e2b842",
    },
};
const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: "myCustomLightTheme",
        themes: {
            myCustomLightTheme,
        },
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
    locale: {
        locale: 'fr',
        fallback: 'fr',
        messages: { fr },
    },
    defaults: {
        global: {},
        VFileInput: {
            variant: "outlined",
            color: "primary",
            density: "compact",
        },
        VTextField: {
            variant: "outlined",
            color: "primary",
            density: "compact",
        },
        VTextarea: {
            variant: "outlined",
            color: "primary",
            density: "compact",
        },
        VAutocomplete: {
            variant: "outlined",
            color: "primary",
            density: "compact",
        },
        VSelect: {
            variant: "outlined",
            color: "primary",
            density: "compact",
        },
        VCheckbox: {
            color: "primary",
        },
        VRadio: {
            color: "primary",
        },
    },
});
const app = createApp(App);
app.use(store);
app.use(router);
app.component("permission-wrapper", PermissionWrapper);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);

app.use(i18n);
app.use(VueViewer);
app.use(vuetify).mount("#app");
